export const theme = {
  main: {
    default: "#FDFDFD",
    main: "#970a2a",
    second: "#fdfdfd",
    mainDark: "#8C082C",
    white: "#FDFDFD",
    buttonColor: {
      disabled: "#222",
      primary: "#fdfdfd",
      secondary: "#970a2a",
    },
    buttonBgColor: {
      disabled: "#333",
      primary: "#970a2a",
      secondary: "#fdfdfd",
    },
  },
  blu: {
    main: "#0A2197",
    second: "#fdfdfd",
    mainDark: "#03045e",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#0A2197",
    },
    buttonBgColor: {
      primary: "#0A2197",
      secondary: "#fdfdfd",
    },
  },
  orange: {
    main: "#ff6200",
    second: "#fdfdfd",
    mainDark: "#D65607",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#E75501",
    },
    buttonBgColor: {
      primary: "#E75501",
      secondary: "#fdfdfd",
    },
  },
  green: {
    main: "#2F970A",
    second: "#FDFDFD",
    mainDark: "#0B7009",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#2F970A",
    },
    buttonBgColor: {
      primary: "#2F970A",
      secondary: "#fdfdfd",
    },
  },
  purple: {
    main: "#940A97",
    second: "#FDFDFD",
    mainDark: "#72088C",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#940A97",
    },
    buttonBgColor: {
      primary: "#940A97",
      secondary: "#fdfdfd",
    },
  },
};
