import styled from "styled-components";

const StyledHeader = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.second};
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  padding: 1rem 4rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 99;

  @media (max-width: 900px) {
    padding: 1.5rem 1rem;
  }
`;

const StyledRightHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCompanyName = styled.div`
  position: relative;

  @media (max-width: 570px) {
    display: none;
  }
`;

const StyledLogoContainer = styled.div`
  width: 1rem;
  height: auto;
  padding-right: 2rem;
  flex: 0.9;
`;

const StyledNavLinks = styled.ul`
  display: flex;
  margin: 0;
`;

const StyledLink = styled.li`
  list-style: none;
  cursor: pointer;
  color: ${(props) => props.theme.main};
  margin-left: 2rem;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    margin-left: 1rem;
  }
`;

export {
  StyledHeader,
  StyledLogoContainer,
  StyledNavLinks,
  StyledRightHeader,
  StyledLink,
  StyledCompanyName,
};
